import React, { Fragment } from 'react'
import SEO from '../components/SEO'
import Hero from '../components/UI/Hero'
import styled from 'styled-components'
import Container from '../components/UI/Container'
import ContactForm from '../components/UI/ContactForm'

const ContactWrapper = styled.div`
  display: flex;
  padding: 2rem 0;
  min-height: calc(100vh - 278px);
`

const Contact = () => (
  <Fragment>
    <SEO title={`Contact`} />
    <Hero heroStyle="tertiary" title="Contact SGL Motors" center />
    <ContactWrapper>
      <Container>
        <ContactForm />
      </Container>
    </ContactWrapper>
  </Fragment>
)

export default Contact
